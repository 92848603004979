import React from 'react';
import { Link, graphql } from 'gatsby';

import SEO from '../../components/Seo';
import Layout from '../../components/Layout';

const Posts = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;
  const { title, date } = frontmatter;

  return (
    <Layout>
      <SEO title="Post" />
      <h1>{title}</h1>
      <p>{date}</p>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <Link to="/">Go to home</Link>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostPage($path: String!) {
    markdownRemark(frontmatter: {path: { eq: $path }}) {
      html
      frontmatter {
        date
        path
        title
      }
    }
  }
`;

export default Posts;
